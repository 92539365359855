import styled from "styled-components"

export const SectionLarge = styled.section`
  max-width: 1160px;
`

export const FormattedDate = props => {
  const { date, locale = "IT", options: dateOptions } = props
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    ...dateOptions,
  }
  return date.toLocaleDateString(locale, options)
}

export const ButtonPrimary = styled.button`
  background: #e96944;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 16px 32px;
  width: 100%;

  &:hover {
    background: #e0603c;
    color: #ffffff;
  }
`

export const ButtonSecondary = styled.button`
  background: #ffffff;
  box-shadow: 0 0 0 1px rgba(104, 95, 116, 0.2);
  border-radius: 5px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #e96944;
  text-align: center;
  padding: 16px 32px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #fafafa;
    color: #e0603c;
  }
`
