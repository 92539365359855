import React from "react"
import Page from "../components/page"
import styled from "styled-components"
import { SectionLarge } from "../components/elements"
import { TwoColumnsGrid } from "../components/containers"

const ContactUsFormContainer = styled.div`
  background-color: #fafafa;
  padding: 48px 60px;

  @media (max-width: 960px) {
    padding: 24px;

    .button-primary {
      width: 100%;
    }
  }
`

const ContactsPage = () => (
  <Page title="Contattaci">
    <SectionLarge>
      <TwoColumnsGrid>
        <div className="text-left">
          <h1>Parliamo di soldi!</h1>
          <p>
            Se vuoi darci suggerimenti, proporre collaborazioni, o solo
            salutarci, contattaci tramite il form di fianco oppure scrivici{" "}
            <a href="mailto:ciao@investitoriribelli.it">
              <strong>qui</strong>
            </a>
            .
            <br />
            <br />
            Saremo felici di entrare in contatto con te! 😊
          </p>
          <hr />
          <p>
            <em>
              Ti ricordiamo che Investitori Ribelli non offre servizi di
              consulenza finanziaria. Per domande e consigli generali a scopo
              educativo ci saremo sempre, ma per qualsiasi richiesta specifica
              ti consigliamo di rivolgervi ad un esperto finanziario.
            </em>
          </p>
        </div>
        <ContactUsFormContainer>
          <form
            className="form"
            action="https://formspree.io/mrgednek"
            method="POST"
          >
            <label>
              <span>Nome</span>
              <input name="name" placeholder="e.g. Charlie T. Munger" />
            </label>
            <label>
              <span>Il tuo indirizzo email</span>
              <input
                placeholder="e.g. charlietmunger@email.com"
                type="text"
                name="_replyto"
              />
            </label>
            <label>
              <span>Messaggio</span>
              <textarea name="message" placeholder="Come possiamo aiutarti?" />
            </label>
            <div className="text-right">
              <button className="button-primary" type="submit">
                Invia
              </button>
            </div>
          </form>
        </ContactUsFormContainer>
      </TwoColumnsGrid>
    </SectionLarge>
  </Page>
)

export default ContactsPage
